import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { Consumer } from 'src/app/shared/models/consumers';
import {
  Organisation,
  OrganisationFeatures,
  PrivacyLevel,
  RoleChoice,
  RoomConsumer,
  StandardPortionSize,
  SupportedCustomData,
  Terminal,
  TerminalType,
  UnauthConsumer,
  User,
} from 'src/app/shared/models/user';
import * as UserActions from './user.actions';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  CosumersSortKey,
  AccessStatusFilter,
  ConsumerStatusFilter,
} from 'src/app/features/managing/shared/models/managable-accesses';
import { OrdersSortKey } from 'src/app/features/managing/shared/models/managable-orders';
import { REPEAT_OPTIONS } from 'src/app/shared/models/orders';

interface UserState {
  currentUser: User | null;
  currentConsumer: Consumer | null;
  simpleConsumer: UnauthConsumer | null;
  roomDetail: RoomConsumer[] | { message: string } | null;
}

const initialState: UserState = {
  currentUser: null,
  currentConsumer: null,
  simpleConsumer: null,
  roomDetail: null,
};

const reducer = createReducer(
  initialState,
  on(UserActions.setUser, (state, { payload }) => ({
    ...state,
    currentUser: payload,
  })),
  on(UserActions.setConsumer, (state, { payload }) => ({
    ...state,
    currentConsumer: payload ?? null,
  })),
  on(UserActions.setRoomDetail, (state, { payload }) => ({
    ...state,
    roomDetail: payload,
  })),
  on(UserActions.setUnauthConsumer, (state, { simpleConsumer }) => ({
    ...state,
    simpleConsumer,
  })),
);

export const usersFeature = createFeature({
  name: 'user',
  reducer,
  extraSelectors: ({ selectCurrentUser, selectSimpleConsumer }) => {
    const prop = <T>(path: string[]) =>
      createSelector(
        selectCurrentUser,
        (user): T | undefined => path.reduce((obj, p) => obj?.[p], user) as T,
      );

    const locOrg = <T>(field: string) =>
      createSelector(selectCurrentUser, (user): T | undefined =>
        user?.location && user?.location_detail?.[field]
          ? (user.location_detail[field] as T)
          : (user?.organisation?.[field] as T),
      );

    const org = <T>(field: string) =>
      createSelector(
        selectCurrentUser,
        selectSimpleConsumer,
        (user, consumer): T | undefined =>
          (user?.organisation || consumer?.organisation)?.[field] as T,
      );

    const role = (roles: RoleChoice[], noTerminal = false) =>
      createSelector(
        selectCurrentUser,
        (user): boolean =>
          !!user?.role &&
          roles.includes(user.role) &&
          (!noTerminal || !user.terminal),
      );

    const simpleOrUser = <T>(field: string) =>
      createSelector(
        selectSimpleConsumer,
        selectCurrentUser,
        (simple, user): T | undefined =>
          simple
            ? ((simple.location_detail?.[field] ??
                simple.organisation?.[field]) as T)
            : ((user?.location_detail?.[field] as T) ??
              (user?.organisation?.[field] as T)),
      );

    return {
      // Terminal selectors
      selectTerminal: prop<Terminal>(['terminal']),
      selectIsTerminal: createSelector(
        selectCurrentUser,
        (user) => !!user?.terminal,
      ),
      selectIsUser: createSelector(
        selectCurrentUser,
        (user) => !user?.terminal,
      ),
      selectTerminalType: prop<TerminalType>(['terminal', 'type']),
      selectTerminalPrivacy: prop<PrivacyLevel>(['terminal', 'privacy']),
      selectTerminalTypeCanteen: createSelector(
        selectCurrentUser,
        (user) => user?.terminal?.type === TerminalType.CANTEEN,
      ),

      // Basic user properties
      selectConsumerId: prop<string>(['id']),
      selectUserEmail: prop<string>(['email']),
      selectIsKdsUser: prop<boolean>(['is_kds']),
      selectIsServiceUser: prop<boolean>(['is_service']),
      selectEmailVerified: prop<boolean>(['status', 'email_verified']),
      selectUserStatusOrderUntil: prop<number>(['status', 'orderby_until']),
      selectUserSettingsLanguage: prop<InterfaceLanguage>([
        'settings',
        'language',
      ]),
      selectUserLocation: prop<number>(['location']),

      // Settings
      selectConsumersSorting: prop<CosumersSortKey>([
        'settings',
        'sorting_consumers',
      ]),
      selectUsersSorting: prop<CosumersSortKey>(['settings', 'sorting_users']),
      selectOrdersSorting: prop<OrdersSortKey>(['settings', 'sorting_orders']),
      selectConsumerColumns: prop<string[]>(['settings', 'columns_consumers']),
      selectUserColumns: prop<string[]>(['settings', 'columns_users']),
      selectOrderColumns: prop<string[]>(['settings', 'columns_orders']),

      // Organization and location
      selectOrganisation: org<Organisation>(''),
      selectLogo: createSelector(
        selectCurrentUser,
        selectSimpleConsumer,
        (user, consumer): string | null =>
          user?.location && user?.location_detail?.logo
            ? user.location_detail.logo
            : (user?.organisation || consumer?.organisation)?.logo || null,
      ),
      selectJoinedDate: prop<string>(['organisation', 'date_joined']),
      selectUserFullName: prop<string>(['name']),

      // Location fallbacks
      selectDisabledRepeatOptions: locOrg<REPEAT_OPTIONS[]>(
        'disabled_repetition_modes',
      ),
      selectDisabledPortionSizes: locOrg<StandardPortionSize[]>(
        'disabled_portion_sizes',
      ),
      selectOrderUntil: locOrg<number>('order_days_until'),
      selectManageOrdersDefaultDays: locOrg<number>(
        'manage_orders_default_days',
      ),
      selectDefaultManageAccessesStatus: locOrg<AccessStatusFilter>(
        'manage_users_default_filter',
      ),
      selectDefaultManageConsumersStatus: locOrg<ConsumerStatusFilter>(
        'manage_consumers_default_filter',
      ),
      selectRealTimeOrders: locOrg<boolean>('real_time_orders'),

      // Role-based
      selectIsConsumer: role([RoleChoice.CONSUMER]),
      selectIsAdmin: role([RoleChoice.ADMIN]),
      selectIsAgent: role([RoleChoice.AGENT]),
      selectIsManager: role([RoleChoice.ADMIN, RoleChoice.EDITOR]),
      selectIsManagerUser: role([RoleChoice.ADMIN, RoleChoice.EDITOR], true),
      selectIsManagerOrAgent: role([
        RoleChoice.ADMIN,
        RoleChoice.EDITOR,
        RoleChoice.AGENT,
      ]),
      selectIsManagerOrAgentUser: role(
        [RoleChoice.ADMIN, RoleChoice.EDITOR, RoleChoice.AGENT],
        true,
      ),

      // Org properties
      selectEnabledFeatures: org<OrganisationFeatures[]>('enabled_features'),
      selectSupportedCustomData: org<SupportedCustomData[]>(
        'supported_custom_data',
      ),
      selectSupportedCustomDataConsumers: org<SupportedCustomData[]>(
        'supported_custom_data_consumers',
      ),
      selectAllergensExtended: org<boolean>('allergens_extended'),
      selectAllergensExtendedLactose: org<boolean>('allergens_lac_extended'),

      // Feature selector
      selectFeature: (feature: OrganisationFeatures) =>
        createSelector(
          selectCurrentUser,
          selectSimpleConsumer,
          (user, consumer): boolean | undefined =>
            (
              user?.organisation || consumer?.organisation
            )?.enabled_features?.includes(feature),
        ),

      // Order-related
      selectUserAllowOrderAllergies: locOrg<boolean>('allow_order_allergies'),
      selectUserAllowOrderIntolerances: locOrg<boolean>(
        'allow_order_intolerances',
      ),
      selectShowQuantity: createSelector(
        selectCurrentUser,
        selectSimpleConsumer,
        (user, simple): boolean =>
          !!simple &&
          !!simpleOrUser<boolean>('unauth_consumers_quantity_selection'),
      ),
      selectShowPortionSize: createSelector(
        selectCurrentUser,
        selectSimpleConsumer,
        (user, simple): boolean =>
          !!simple &&
          !!simpleOrUser<boolean>('unauth_consumers_portion_size_selection'),
      ),
      selectQuantityInputField: simpleOrUser<boolean>('quantity_input_field'),
      selectQuantityFieldIncrement: simpleOrUser<number>(
        'quantity_input_field_increment',
      ),
    };
  },
});
