import { DOCUMENT, KeyValuePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  OnDestroy,
  Renderer2,
  ViewChild,
  inject,
  input,
  output,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDatepickerInput,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

import { UtilsService } from '../../services/utils.service';
import { InterfaceLanguage, LANGUAGES } from './../../constants/languages';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { ServerFieldErrorDirective } from '../../directives/server-field-error.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BaseModel, BaseName } from '../../models/globals';
import { SelectFieldModel } from '../../models/misc';

type InputType =
  | 'text'
  | 'password'
  | 'email'
  | 'select'
  | 'date'
  | 'time'
  | 'checkbox'
  | 'textarea'
  | 'number';

@Component({
  selector: 'win-field-with-errors',
  templateUrl: './field-with-errors.component.html',
  styleUrls: ['./field-with-errors.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FieldWithErrorsComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    KeyValuePipe,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatProgressBarModule,
    MatSelectModule,
    ReactiveFormsModule,
    ServerFieldErrorDirective,
    TranslocoPipe,
  ],
})
export class FieldWithErrorsComponent
  implements AfterViewInit, ControlValueAccessor, OnDestroy
{
  private readonly document = inject(DOCUMENT);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly injector = inject(Injector);
  private readonly renderer = inject(Renderer2);
  private readonly utilsService = inject(UtilsService);

  readonly autofocus = input(false);
  readonly autoclear = input(false);
  readonly autocomplete = input<string>();
  readonly formControlName = input.required<string>();
  readonly icon = input<string>();
  readonly label = input<string>();
  readonly lang = input<InterfaceLanguage>();
  readonly multiple = input(false);
  readonly optionLabel = input<string>();
  readonly optionLabelMulti = input(undefined);
  readonly placeholder = input<string>();
  readonly required = input(false);
  readonly selectOptions = input<
    BaseModel[] | SelectFieldModel[] | { id: string }[] | number[] | string[]
  >();
  readonly subscriptSizing = input<'fixed' | 'dynamic'>('fixed');
  readonly showProgressBar = input(false);
  readonly suffixIcon = input<string>();
  readonly translationKey = input<string>();
  readonly type = input.required<InputType>();
  readonly valueField = input<string>();

  readonly fieldBlur = output<FocusEvent>();

  AUTOCLEAR_DELAY = 5000;
  _datepickerInput: MatDatepickerInput<Date>;
  private clearTimer: NodeJS.Timeout;
  private focusUnlistener: () => void;
  private autofocusEnabled = false;

  @ViewChild('datepickerInput', { read: MatDatepickerInput })
  set datepickerInput(value: MatDatepickerInput<Date>) {
    this._datepickerInput = value;
  }
  readonly formControlDirective = viewChild(FormControlDirective);
  readonly inputElement =
    viewChild<ElementRef<HTMLInputElement>>('inputElement');

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  get control(): FormControl {
    return this.controlContainer?.control?.get(
      this.formControlName(),
    ) as FormControl;
  }

  constructor() {
    this.utilsService.enableAutofocus
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => (this.autofocusEnabled = state));
  }

  ngAfterViewInit(): void {
    if (this.focusUnlistener) this.focusUnlistener();
    if (this.autofocus() && this.autofocusEnabled) {
      this.focusUnlistener = this.renderer.listen(this.document, 'focus', () =>
        this.focusInput(),
      );

      this.focusInput();
    }
    this.controlContainer.control.statusChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        setTimeout(() => {
          this.changeDetectorRef.detectChanges();
        }, 0);
      });
  }

  private focusInput(): void {
    const inputElement = this.inputElement();
    if (inputElement.nativeElement) {
      (
        this.renderer.selectRootElement(
          inputElement.nativeElement,
        ) as HTMLInputElement
      ).focus();
    }
  }

  changeDate({ value }: MatDatepickerInputEvent<Date>): void {
    this.control.setValue(value);
  }

  blur(event: FocusEvent): void {
    if (this.autofocus() && this.autofocusEnabled) {
      const inputElement = event?.target as HTMLInputElement;
      requestAnimationFrame(() => inputElement?.focus());
    }
    this.fieldBlur.emit(event);
  }

  getFallbackLang = (option: BaseName) => {
    const langKey = Object.keys(option || {}).filter(
      (k) => Object.keys(LANGUAGES).includes(k) && option[k],
    )?.[0] as InterfaceLanguage;
    return option?.[langKey || this.lang()] || '';
  };

  onInputChange(): void {
    // Clear any existing timers
    if (this.clearTimer) window.clearTimeout(this.clearTimer);

    // Set a new timer if autoclear is true
    if (this.autoclear()) {
      this.clearTimer = setTimeout(() => {
        this.control.reset();
        this.control.setErrors(null);
        this.control.markAsPristine();
        this.control.markAsUntouched();
      }, this.AUTOCLEAR_DELAY);
    }
  }

  openDatepicker(): void {
    this._datepickerInput?._datepicker.open();
  }

  parseInt = (value: string): number => parseInt(value);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.formControlDirective()?.valueAccessor.registerOnChange(fn);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.formControlDirective()?.valueAccessor.registerOnTouched(fn);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.formControlDirective()?.valueAccessor.writeValue(obj);
  }

  selectionChange(event: MatSelectChange): void {
    this.control.setValue(event?.value, { emitEvent: false });
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective()?.valueAccessor.setDisabledState(isDisabled);
  }

  isSelectFieldModel(
    item: unknown,
  ): item is SelectFieldModel | BaseModel | { id: string } {
    return item !== null && typeof item === 'object' && 'id' in item;
  }

  isSimpleIdObject(item: unknown): item is { id: string } {
    return (
      item !== null &&
      typeof item === 'object' &&
      'id' in item &&
      Object.keys(item).length === 1
    );
  }

  getDisplayValue(
    item: SelectFieldModel | BaseModel | { id: string } | number | string,
  ): string {
    if (this.isSelectFieldModel(item)) {
      if (this.isSimpleIdObject(item)) return item.id;
      return 'name' in item
        ? item.name
        : item[this.lang()] || this.getFallbackLang(item);
    }
    return String(item);
  }

  ngOnDestroy(): void {
    if (this.focusUnlistener) this.focusUnlistener();
    if (this.clearTimer) clearTimeout(this.clearTimer);
  }
}
